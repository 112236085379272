import React from 'react';
import AllRoute from '../router'
import '../../sass/style.scss';
import AllprojectsMenu from '../../components/allprojectsMenu';


const App = () => { 

  return (
    <div className="App" id='home'>
          <AllRoute/>
          <AllprojectsMenu/>
    </div>
  );
}

export default App;
