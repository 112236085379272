import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import PreviewPage from '../PreviewPage/PreviewPage';



const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<PreviewPage />} />
          <Route path='home' element={<PreviewPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
