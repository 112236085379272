import React from 'react';
import bg from '../../images/preview/footer.jpg'
import shape1 from '../../images/preview/shape/1.svg'
import shape2 from '../../images/preview/shape/2.svg'
import shape3 from '../../images/preview/shape/3.svg'
import shape4 from '../../images/preview/shape/4.svg'


const Footer = () => {
    return(
        <div>
            <footer className="preview-site-footer" style={{ background: `url(${bg})no-repeat center top / cover` }}>
                <div className="preview-upper-footer">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="preview-footer-text">
                                    <span>Everything your Want for Your Wedding</span>
                                    <h2>Let’s Celebrate Love With Habibi</h2>
                                    <a className="theme-btn-s2" target="_blank"
                                        href="https://themeforest.net/item/habibi-wedding-wedding-planner-wordpress-theme/48974620">Purchase Now </a>

                                    <div className="shape-1"><img src={shape1} alt=""/>
                                    </div>
                                    <div className="shape-2"><img src={shape2} alt=""/>
                                    </div>
                                    <div className="shape-3"><img src={shape3} alt=""/>
                                    </div>
                                    <div className="shape-4"><img src={shape4} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="pthumb">
                <div className="thumb-inner">
                    <h2>15 <small>+</small> <span>Home</span></h2>
                </div>
            </div>
            <div className="pthumb2">
                <div className="thumb-inner">
                    <h2>60 <small>+</small> <span>Pages</span></h2>
                </div>
            </div>
        </div>
    )
}

export default Footer;