import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { JackInTheBox, Slide } from "react-awesome-reveal";
import bg from '../../images/preview/hero-bg.png'
import mouse from '../../images/preview/mouse-scroll.svg'
import hero1 from '../../images/preview/img-1.jpg'
import hero2 from '../../images/preview/img-2.jpg'
import hero3 from '../../images/preview/img-3.jpg'
import hero4 from '../../images/preview/img-4.jpg'
import hero5 from '../../images/preview/img-5.jpg'
import hero6 from '../../images/preview/img-6.jpg'

import fl1 from '../../images/preview/flower1.svg'
import fl2 from '../../images/preview/flower2.svg'
import fl3 from '../../images/preview/shape.png'
import middle from '../../images/preview/middle.png'

const Hero = () => {
    return (
        <section className="preview-hero" style={{ background: `url(${bg})no-repeat center top / cover` }}>
            <div className="container-fluid">
                <div className="preview-hero-wrap">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-12">
                            <div className="preview-hero-items s1">
                                <div className="preview-hero-item">
                                    <Slide cascade direction="left" duration="1000" triggerOnce="true">
                                        <img src={hero1} alt="" />
                                    </Slide>
                                </div>
                                <div className="preview-hero-item">
                                    <Slide cascade direction="left" duration="1500" triggerOnce="true">
                                        <img src={hero2} alt="" />
                                    </Slide>
                                </div>
                                <div className="preview-hero-item">
                                    <Slide cascade direction="left" duration="1200" triggerOnce="true">
                                        <img src={hero3} alt="" />
                                    </Slide>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="preview-middle-items">
                                <div className="preview-middle-text">
                                    <span>Best Wedding Theme</span>
                                    <h2>Welcome to Habibi</h2>
                                    <h4>Best Wedding Multipurpose
                                        Theme</h4>
                                    <a target="_blank" href="https://themeforest.net/item/habibi-wedding-wedding-planner-wordpress-theme/48974620"
                                        className="theme-btn-s2">Purchase Now</a>
                                    <AnchorLink href="#demo" className="scrool"><img
                                        src={mouse} alt="" /></AnchorLink>
                                    <div className="flower-1">
                                        <JackInTheBox direction="left" duration="3000" triggerOnce="true">
                                            <img src={fl1} alt="" />
                                        </JackInTheBox>
                                    </div>
                                    <div className="flower-2">
                                        <JackInTheBox direction="right" duration="3000" triggerOnce="true">
                                            <img src={fl2} alt="" />
                                        </JackInTheBox>
                                    </div>
                                </div>
                                <div className="preview-middle-img-wrap">
                                    <div className="preview-middle-img">
                                        <img src={middle} alt="" />
                                        <div className="f-shape"><img src={fl3} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12">
                            <div className="preview-hero-items s2">
                                <div className="preview-hero-item">
                                    <Slide cascade direction="right" duration="1000" triggerOnce="true">
                                        <img src={hero4} alt="" />
                                    </Slide>
                                </div>
                                <div className="preview-hero-item">
                                    <Slide cascade direction="right" duration="1500" triggerOnce="true">
                                        <img src={hero5} alt="" />
                                    </Slide>
                                </div>
                                <div className="preview-hero-item">
                                    <Slide cascade direction="right" duration="1200" triggerOnce="true">
                                        <img src={hero6} alt="" />
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;