import React from 'react'

import demo1 from '../../images/preview/demo/img-1.jpg'
import demo2 from '../../images/preview/demo/img-2.jpg'
import demo3 from '../../images/preview/demo/img-3.jpg'
import demo4 from '../../images/preview/demo/img-5.jpg'
import demo5 from '../../images/preview/demo/img-4.jpg'
import demo6 from '../../images/preview/demo/img-6.jpg'
import demo7 from '../../images/preview/demo/img-7.jpg'
import demo8 from '../../images/preview/demo/img-8.jpg'
import demo9 from '../../images/preview/demo/img-9.jpg'

const Demo = () => {
    return (
        <section className="wpo-demo-section section-padding" id="demo">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col col-lg-6">
                        <div className="preview-section-title">
                            <i><img src="assets/images/preview/title-1.png" alt="" /></i>
                            <h2>Wedding & Invitation Homes</h2>
                            <p>We Happily Prepared 09 Homes for Your Big Day. Pick One of Our Beautiful Homepages to
                                Start Your Dreamy Wedding Website!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-demo-grids clearfix">
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/" target="_blank"><img src={demo1} alt="" /></a>
                                </div>
                                <h3>Wedding Home 1</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/wedding-home-2/" target="_blank"><img
                                        src={demo2} alt="" /></a>
                                </div>
                                <h3>Wedding Home 2</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/announcement-home-1/" target="_blank"><img
                                        src={demo3} alt="" /></a>
                                </div>
                                <h3>Announcement Home 1</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/announcement-home-2/" target="_blank"><img
                                        src={demo4} alt="" /></a>
                                </div>
                                <h3>Announcement Home 2</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/muslim-wedding-home/" target="_blank"><img
                                        src={demo5} alt="" /></a>
                                </div>
                                <h3>Muslim Wedding Home</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/asian-wedding-home/" target="_blank"><img
                                        src={demo6} alt="" /></a>
                                </div>
                                <h3>Asian Wedding Home</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/muslim-homertl/" target="_blank"><img
                                        src={demo7} alt="" /></a>
                                </div>
                                <h3>Muslim Wedding Home(RTL)</h3>
                            </div>
                            <div className="grid">
                                <div className="inner-s2">
                                    <a href="https://wpolive.com/habibi/invitation/" target="_blank"><img
                                        src={demo8} alt="" /></a>
                                </div>
                                <h3>Wedding Invitation 1</h3>
                            </div>
                            <div className="grid">
                                <div className="inner-s2">
                                    <a href="https://wpolive.com/habibi/wedding-invitation-2/" target="_blank"><img
                                        src={demo9} alt="" /></a>
                                </div>
                                <h3>Wedding Invitation 2</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Demo;