import React from 'react'

import shape from '../../images/preview/title-2.png'
import demo1 from '../../images/preview/demo/img-10.jpg'
import demo2 from '../../images/preview/demo/img-11.jpg'
import demo3 from '../../images/preview/demo/img-12.jpg'

const Demo2 = () => {
    return (
        <section className="wpo-demo-section section-padding pt-120" id="demo2">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col col-lg-6">
                        <div className="preview-section-title">
                            <i><img src={shape} alt=""/></i>
                            <h2>Wedding & Event Planner Homes</h2>
                            <p>We Have 03 Wedding & Event Planner For Your Business. Hope You May Like One of Our
                                Beautiful Homepages to Start Your Website!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-demo-grids clearfix">
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/wedding-planner-1/" target="_blank"><img src={demo1} alt=""/></a>
                                </div>
                                <h3>Wedding Planner 1</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/wedding-planner-2/" target="_blank"><img src={demo2} alt=""/></a>
                                </div>
                                <h3>Wedding Planner 2</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/wedding-planner-3/" target="_blank"><img src={demo3} alt=""/></a>
                                </div>
                                <h3>Wedding Planner 3</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Demo2;