import React, { Component } from 'react'
import './style.css';
import AllPrjects from '../allprojects'

export default class AllprojectsMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: false,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {

        const { isMenuShow } = this.state;

        return (
            <div>
                <div className={`projectMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close">
                         <div className="clox" onClick={this.menuHandler}><i className="fa fa-close"></i></div>
                    </div>
                    <div className="project-site-menu">
                        <AllPrjects/>
                    </div>
                    <div className="closer" onClick={this.menuHandler}></div>
                </div>

                <div className="projectshowmenu" onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        <span>Our Themes and Templates</span>
                    </button>
                </div>       
            </div>

        )
    }
}
