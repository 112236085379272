import React from 'react'

import shape from '../../images/preview/title-3.png'
import demo1 from '../../images/preview/demo/img-13.jpg'
import demo2 from '../../images/preview/demo/img-14.jpg'
import demo3 from '../../images/preview/demo/img-15.jpg'

const Demo3 = () => {
    return (
        <section className="wpo-demo-section section-padding pt-100" id="demo3">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col col-lg-6">
                        <div className="preview-section-title">
                            <i><img src={shape} alt=""/></i>
                            <h2>Wedding Shop Homes</h2>
                            <p>We Have 03 Wedding Shop For Your eCommerce Business. Hope You May Like One of Our
                                Beautiful Homepages to Start Your Website!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-demo-grids clearfix">
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/bridal-shop/" target="_blank"><img src={demo1} alt=""/></a>
                                </div>
                                <h3>Bridal Shop</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/cake-shop/" target="_blank"><img src={demo2} alt=""/></a>
                                </div>
                                <h3>Wedding Bouquet Shop</h3>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <a href="https://wpolive.com/habibi/bouquet-shop/" target="_blank"><img src={demo3} alt=""/></a>
                                </div>
                                <h3>Wedding Cake Shop</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Demo3;