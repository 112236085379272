import React from 'react'
import bg from '../../images/preview/bg.jpg'
import shape1 from '../../images/preview/shape/1.svg'
import shape2 from '../../images/preview/shape/2.svg'
import shape3 from '../../images/preview/shape/3.svg'
import shape4 from '../../images/preview/shape/4.svg'

const Banner = () => {
    return (
        <section className="wpo-offer-section-s3 preview-banner">
            <div className="container-fluid">
                <div className="wpo-offer-wrap">
                    <div className="left-img" style={{ background: `url(${bg})no-repeat center top / cover` }}>
                    </div>
                    <div className="wpo-offer-items">
                        <div className="wpo-offer-item">
                            <div className="wpo-offer-right">
                                <span>What we Have</span>
                                <h2>Stunning & Unique Wedding Website For Creating Your Dreamy Website.</h2>
                                <p>We create for you a complete package of wedding website. Here we have more than 14+
                                    wedding, invitation, planner, gallery, RSVP & shop home pages. we tried our best to
                                    complete your needs to build your wished website.</p>
                                <a href="https://themeforest.net/item/habibi-wedding-wedding-planner-wordpress-theme/48974620" className="theme-btn">Purchase Now</a>
                            </div>
                            <div className="shape-1"><img src={shape1} alt=""/>
                            </div>
                            <div className="shape-2"><img src={shape2} alt=""/>
                            </div>
                            <div className="shape-3"><img src={shape3} alt=""/>
                            </div>
                            <div className="shape-4"><img src={shape4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Banner;