import React from 'react'
import shape1 from '../../images/preview/title-1.png'
import icon1 from '../../images/preview/icon/1.svg'
import icon2 from '../../images/preview/icon/2.svg'
import icon3 from '../../images/preview/icon/3.svg'
import icon4 from '../../images/preview/icon/4.svg'
import icon5 from '../../images/preview/icon/5.svg'
import icon6 from '../../images/preview/icon/6.svg'


const Features = () => {
    return (
        <section id="features" className="wpo-features-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-6">
                        <div className="preview-section-title">
                            <i><img src={shape1} alt="" /></i>
                            <h2>Amazing Features</h2>
                            <p>We Happily Prepared 09 Homes for Your Big Day. Pick One of Our Beautiful Homepages to
                                Start Your Dreamy Wedding Website!</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-features-wrapper">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i><img src={icon1} alt="" /></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Hand-crafted Design</h2>
                                    <p>Habibi brings a brand new design for Wedding.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i><img src={icon2} alt="" /></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Fully Responsive</h2>
                                    <p>We create fully responsive template for you.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i><img src={icon3} alt="" /></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Lifetime License</h2>
                                    <p>We are providing you the lifetime license.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i><img src={icon4} alt="" /></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Top-Notch Support</h2>
                                    <p>We guarantee the best possible fastest support .</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i><img src={icon5} alt="" /></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Free Updates</h2>
                                    <p>We mainly focuse on our client & buyers demands</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i><img src={icon6} alt="" /></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>RSVP Forms</h2>
                                    <p>Habibi brings a RSVP form for Wedding.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default Features;