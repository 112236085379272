import React, { Fragment } from 'react';
import Scrollbar from '../../components/scrollbar'
import Hero from '../../components/hero';
import Demo from '../../components/demo';
import Demo2 from '../../components/demo2';
import Demo3 from '../../components/demo3';
import Banner from '../../components/banner';
import Features from '../../components/Features';
import Footer from '../../components/footer';
import Plugins from '../../components/Plugins';

const PreviewPage = () => {
    return (
        <Fragment>
            <Hero/>
            <Demo/>
            <Demo2/>
            <Demo3/>
            <Banner/>
            <Features/>
            <Plugins/>
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};

export default PreviewPage;
